$btn-border-radius-lg: 0;

@import "~bootstrap/scss/bootstrap";

html,
body,
#root {
  min-height: 100%;
}

#ourwedding {
  //min-height: 100vh;
  background-image: url("./assets/images/IMG_0722.webp");
  background-position: center;
  background-size: cover;
  // background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  //align-items: center;
  position: relative;
  padding: 100px 0;
  &:before {
    content: "";
    background: color-mix(in srgb, #000000, transparent 50%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
}

#howwemeet {
  padding: 80px 0 80px 0;
}

#locations {
  padding: 0 0 60px 0;
}

#restaurant {
  padding: 0 0 150px 0;
  .wedding-wrapper {
    position: relative;
    // margin: -80px 80px 0 80px;
    // padding-top: 100px;
  }

  .stack-item {
    flex-basis: 50%;
    flex-grow: 1;
  }

  svg {
    height: 15px;
    width: 15px;
  }
}

#welcome {
  padding: 80px 0;
}

.input-v1 {
  border-radius: 0;
  outline: none !important;
  box-shadow: none !important;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  &:hover {
    box-shadow: 0 1px 0 0 #dee2e6 !important;
  }
}

#thankyou {
  padding: 80px 0;
  background-color: #e8f6ff;
}

.footer {
  background-color: #33424e;
  padding: 80px 0;
}

@media (min-width: 768px) {
  #ourwedding {
    background-size: cover;
    padding: 250px 0;
    // background-attachment: fixed;
  }
}

// .Toastify__toast-theme--colored.Toastify__toast--default {
//   background-color: #bdb7b1;
// }

p {
  font-size: 18px !important;
}
